import { graphql } from 'gatsby';
import { Paragraph } from '@entur/typography';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import ColorsDataVisualisation, { DataIllustrations } from '~/components/ColorsDataVisualisation';
import * as React from 'react';
export default {
  graphql,
  Paragraph,
  PageHeader,
  ImageDisplay,
  DoDontGroup,
  DoDontCard,
  ColorsDataVisualisation,
  DataIllustrations,
  React
};