import { graphql } from "gatsby";
import { BannerAlertBox } from '@entur/alert';
import { Link } from 'docz';
import { Link as LinkText } from '@entur/typography';
import PageHeader from '~/components/PageHeader';
import { TypographyDisplay } from '~/components/TypographyDisplay';
import { ImageDisplay } from '~/components/ImageDisplay';
import * as React from 'react';
export default {
  graphql,
  BannerAlertBox,
  Link,
  LinkText,
  PageHeader,
  TypographyDisplay,
  ImageDisplay,
  React
};