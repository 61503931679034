import { graphql } from "gatsby";
import { GridContainer, GridItem } from '@entur/grid';
import { colors, space } from '@entur/tokens';
import { StrongText, Paragraph } from '@entur/typography';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
import BaseCardDesignEntur from '~/components/BaseCardDesignEntur';
import * as React from 'react';
export default {
  graphql,
  GridContainer,
  GridItem,
  colors,
  space,
  StrongText,
  Paragraph,
  PageHeader,
  ImageDisplay,
  BaseCardDesignEntur,
  React
};