import { graphql } from 'gatsby';
import PageHeader from '~/components/PageHeader';
import { Paragraph, Heading2, Link } from '@entur/typography';
import { PrimaryButton } from '@entur/button';
import { ImageDisplay } from '~/components/ImageDisplay';
import * as React from 'react';
export default {
  graphql,
  PageHeader,
  Paragraph,
  Heading2,
  Link,
  PrimaryButton,
  ImageDisplay,
  React
};