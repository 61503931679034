import { graphql } from "gatsby";
import { data, space } from '@entur/tokens';
import { Paragraph, StrongText, UnorderedList, ListItem } from '@entur/typography';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import BaseCardDesignEntur from '~/components/BaseCardDesignEntur';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
import * as React from 'react';
export default {
  graphql,
  data,
  space,
  Paragraph,
  StrongText,
  UnorderedList,
  ListItem,
  DoDontGroup,
  DoDontCard,
  BaseCardDesignEntur,
  PageHeader,
  ImageDisplay,
  React
};