import { graphql } from 'gatsby';
import { borderWidths, colors, space } from '@entur/tokens';
import { Table, TableHead, TableRow, HeaderCell, TableBody } from '@entur/table';
import { GridContainer } from '@entur/grid';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import Colors from '~/components/Colors';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
import * as React from 'react';
export default {
  graphql,
  borderWidths,
  colors,
  space,
  Table,
  TableHead,
  TableRow,
  HeaderCell,
  TableBody,
  GridContainer,
  DoDontGroup,
  DoDontCard,
  Colors,
  PageHeader,
  ImageDisplay,
  React
};