import { graphql } from 'gatsby';
import { Paragraph, Heading5 } from '@entur/typography';
import { space } from '@entur/tokens';
import { GridContainer, GridItem } from '@entur/grid';
import { BannerAlertBox } from '@entur/alert';
import { DownloadBrushes } from '~/gatsby-theme-docz/components/DownloadBrushes';
import PageHeader from '~/components/PageHeader';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import { ImageDisplay } from '~/components/ImageDisplay';
import * as React from 'react';
export default {
  graphql,
  Paragraph,
  Heading5,
  space,
  GridContainer,
  GridItem,
  BannerAlertBox,
  DownloadBrushes,
  PageHeader,
  DoDontGroup,
  DoDontCard,
  ImageDisplay,
  React
};