// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---content-components-feedback-alerts-mdx": () => import("./../../../content/components/feedback/alerts.mdx" /* webpackChunkName: "component---content-components-feedback-alerts-mdx" */),
  "component---content-components-feedback-badge-mdx": () => import("./../../../content/components/feedback/badge.mdx" /* webpackChunkName: "component---content-components-feedback-badge-mdx" */),
  "component---content-components-feedback-copyable-text-mdx": () => import("./../../../content/components/feedback/copyable-text.mdx" /* webpackChunkName: "component---content-components-feedback-copyable-text-mdx" */),
  "component---content-components-feedback-loaders-mdx": () => import("./../../../content/components/feedback/loaders.mdx" /* webpackChunkName: "component---content-components-feedback-loaders-mdx" */),
  "component---content-components-feedback-popover-mdx": () => import("./../../../content/components/feedback/popover.mdx" /* webpackChunkName: "component---content-components-feedback-popover-mdx" */),
  "component---content-components-feedback-skeletons-mdx": () => import("./../../../content/components/feedback/skeletons.mdx" /* webpackChunkName: "component---content-components-feedback-skeletons-mdx" */),
  "component---content-components-feedback-tooltips-mdx": () => import("./../../../content/components/feedback/tooltips.mdx" /* webpackChunkName: "component---content-components-feedback-tooltips-mdx" */),
  "component---content-components-index-mdx": () => import("./../../../content/components/index.mdx" /* webpackChunkName: "component---content-components-index-mdx" */),
  "component---content-components-knapper-chips-mdx": () => import("./../../../content/components/knapper/chips.mdx" /* webpackChunkName: "component---content-components-knapper-chips-mdx" */),
  "component---content-components-knapper-flytende-knapper-mdx": () => import("./../../../content/components/knapper/flytende-knapper.mdx" /* webpackChunkName: "component---content-components-knapper-flytende-knapper-mdx" */),
  "component---content-components-knapper-ikon-knapper-mdx": () => import("./../../../content/components/knapper/ikon-knapper.mdx" /* webpackChunkName: "component---content-components-knapper-ikon-knapper-mdx" */),
  "component---content-components-knapper-kvadratiske-knapper-mdx": () => import("./../../../content/components/knapper/kvadratiske-knapper.mdx" /* webpackChunkName: "component---content-components-knapper-kvadratiske-knapper-mdx" */),
  "component---content-components-knapper-standardknapper-mdx": () => import("./../../../content/components/knapper/standardknapper.mdx" /* webpackChunkName: "component---content-components-knapper-standardknapper-mdx" */),
  "component---content-components-layout-og-flater-cards-mdx": () => import("./../../../content/components/layout-og-flater/cards.mdx" /* webpackChunkName: "component---content-components-layout-og-flater-cards-mdx" */),
  "component---content-components-layout-og-flater-drawers-mdx": () => import("./../../../content/components/layout-og-flater/drawers.mdx" /* webpackChunkName: "component---content-components-layout-og-flater-drawers-mdx" */),
  "component---content-components-layout-og-flater-expandables-mdx": () => import("./../../../content/components/layout-og-flater/expandables.mdx" /* webpackChunkName: "component---content-components-layout-og-flater-expandables-mdx" */),
  "component---content-components-layout-og-flater-kontrastseksjoner-mdx": () => import("./../../../content/components/layout-og-flater/kontrastseksjoner.mdx" /* webpackChunkName: "component---content-components-layout-og-flater-kontrastseksjoner-mdx" */),
  "component---content-components-layout-og-flater-mediacard-mdx": () => import("./../../../content/components/layout-og-flater/mediacard.mdx" /* webpackChunkName: "component---content-components-layout-og-flater-mediacard-mdx" */),
  "component---content-components-layout-og-flater-modaler-mdx": () => import("./../../../content/components/layout-og-flater/modaler.mdx" /* webpackChunkName: "component---content-components-layout-og-flater-modaler-mdx" */),
  "component---content-components-layout-og-flater-tabeller-mdx": () => import("./../../../content/components/layout-og-flater/tabeller.mdx" /* webpackChunkName: "component---content-components-layout-og-flater-tabeller-mdx" */),
  "component---content-components-layout-og-flater-tabs-mdx": () => import("./../../../content/components/layout-og-flater/tabs.mdx" /* webpackChunkName: "component---content-components-layout-og-flater-tabs-mdx" */),
  "component---content-components-layout-og-flater-tags-mdx": () => import("./../../../content/components/layout-og-flater/tags.mdx" /* webpackChunkName: "component---content-components-layout-og-flater-tags-mdx" */),
  "component---content-components-navigasjon-breadcrumbs-mdx": () => import("./../../../content/components/navigasjon/breadcrumbs.mdx" /* webpackChunkName: "component---content-components-navigasjon-breadcrumbs-mdx" */),
  "component---content-components-navigasjon-overflow-mdx": () => import("./../../../content/components/navigasjon/overflow.mdx" /* webpackChunkName: "component---content-components-navigasjon-overflow-mdx" */),
  "component---content-components-navigasjon-paginering-mdx": () => import("./../../../content/components/navigasjon/paginering.mdx" /* webpackChunkName: "component---content-components-navigasjon-paginering-mdx" */),
  "component---content-components-navigasjon-sidemeny-mdx": () => import("./../../../content/components/navigasjon/sidemeny.mdx" /* webpackChunkName: "component---content-components-navigasjon-sidemeny-mdx" */),
  "component---content-components-navigasjon-stepper-mdx": () => import("./../../../content/components/navigasjon/stepper.mdx" /* webpackChunkName: "component---content-components-navigasjon-stepper-mdx" */),
  "component---content-components-navigasjon-toppmeny-mdx": () => import("./../../../content/components/navigasjon/toppmeny.mdx" /* webpackChunkName: "component---content-components-navigasjon-toppmeny-mdx" */),
  "component---content-components-reise-legs-mdx": () => import("./../../../content/components/reise/legs.mdx" /* webpackChunkName: "component---content-components-reise-legs-mdx" */),
  "component---content-components-reise-travelheader-mdx": () => import("./../../../content/components/reise/travelheader.mdx" /* webpackChunkName: "component---content-components-reise-travelheader-mdx" */),
  "component---content-components-reise-travelswitch-mdx": () => import("./../../../content/components/reise/travelswitch.mdx" /* webpackChunkName: "component---content-components-reise-travelswitch-mdx" */),
  "component---content-components-reise-traveltag-mdx": () => import("./../../../content/components/reise/traveltag.mdx" /* webpackChunkName: "component---content-components-reise-traveltag-mdx" */),
  "component---content-components-ressurser-changelog-mdx": () => import("./../../../content/components/ressurser/changelog.mdx" /* webpackChunkName: "component---content-components-ressurser-changelog-mdx" */),
  "component---content-components-ressurser-grid-mdx": () => import("./../../../content/components/ressurser/grid.mdx" /* webpackChunkName: "component---content-components-ressurser-grid-mdx" */),
  "component---content-components-ressurser-ikoner-ikoner-mdx": () => import("./../../../content/components/ressurser/ikoner/ikoner.mdx" /* webpackChunkName: "component---content-components-ressurser-ikoner-ikoner-mdx" */),
  "component---content-components-ressurser-tilgjengelighet-mdx": () => import("./../../../content/components/ressurser/tilgjengelighet.mdx" /* webpackChunkName: "component---content-components-ressurser-tilgjengelighet-mdx" */),
  "component---content-components-ressurser-typografi-mdx": () => import("./../../../content/components/ressurser/typografi.mdx" /* webpackChunkName: "component---content-components-ressurser-typografi-mdx" */),
  "component---content-components-skjemaelementer-checkboxes-mdx": () => import("./../../../content/components/skjemaelementer/checkboxes.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-checkboxes-mdx" */),
  "component---content-components-skjemaelementer-checkboxpanel-mdx": () => import("./../../../content/components/skjemaelementer/checkboxpanel.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-checkboxpanel-mdx" */),
  "component---content-components-skjemaelementer-datepicker-mdx": () => import("./../../../content/components/skjemaelementer/datepicker.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-datepicker-mdx" */),
  "component---content-components-skjemaelementer-dropdowns-mdx": () => import("./../../../content/components/skjemaelementer/dropdowns.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-dropdowns-mdx" */),
  "component---content-components-skjemaelementer-fileupload-mdx": () => import("./../../../content/components/skjemaelementer/fileupload.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-fileupload-mdx" */),
  "component---content-components-skjemaelementer-multiselect-mdx": () => import("./../../../content/components/skjemaelementer/multiselect.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-multiselect-mdx" */),
  "component---content-components-skjemaelementer-radioknapper-mdx": () => import("./../../../content/components/skjemaelementer/radioknapper.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-radioknapper-mdx" */),
  "component---content-components-skjemaelementer-radiopanel-mdx": () => import("./../../../content/components/skjemaelementer/radiopanel.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-radiopanel-mdx" */),
  "component---content-components-skjemaelementer-segmented-mdx": () => import("./../../../content/components/skjemaelementer/segmented.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-segmented-mdx" */),
  "component---content-components-skjemaelementer-switch-mdx": () => import("./../../../content/components/skjemaelementer/switch.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-switch-mdx" */),
  "component---content-components-skjemaelementer-textareas-mdx": () => import("./../../../content/components/skjemaelementer/textareas.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-textareas-mdx" */),
  "component---content-components-skjemaelementer-textfields-mdx": () => import("./../../../content/components/skjemaelementer/textfields.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-textfields-mdx" */),
  "component---content-components-skjemaelementer-timepicker-mdx": () => import("./../../../content/components/skjemaelementer/timepicker.mdx" /* webpackChunkName: "component---content-components-skjemaelementer-timepicker-mdx" */),
  "component---content-home-404-mdx": () => import("./../../../content/home/404.mdx" /* webpackChunkName: "component---content-home-404-mdx" */),
  "component---content-home-index-mdx": () => import("./../../../content/home/index.mdx" /* webpackChunkName: "component---content-home-index-mdx" */),
  "component---content-identitet-index-mdx": () => import("./../../../content/identitet/index.mdx" /* webpackChunkName: "component---content-identitet-index-mdx" */),
  "component---content-identitet-introduksjon-merkevareplattform-mdx": () => import("./../../../content/identitet/introduksjon/merkevareplattform.mdx" /* webpackChunkName: "component---content-identitet-introduksjon-merkevareplattform-mdx" */),
  "component---content-identitet-introduksjon-toneofvoice-mdx": () => import("./../../../content/identitet/introduksjon/toneofvoice.mdx" /* webpackChunkName: "component---content-identitet-introduksjon-toneofvoice-mdx" */),
  "component---content-identitet-introduksjon-verdier-mdx": () => import("./../../../content/identitet/introduksjon/verdier.mdx" /* webpackChunkName: "component---content-identitet-introduksjon-verdier-mdx" */),
  "component---content-identitet-maler-epost-mdx": () => import("./../../../content/identitet/maler/epost.mdx" /* webpackChunkName: "component---content-identitet-maler-epost-mdx" */),
  "component---content-identitet-maler-powerpoint-mdx": () => import("./../../../content/identitet/maler/powerpoint.mdx" /* webpackChunkName: "component---content-identitet-maler-powerpoint-mdx" */),
  "component---content-identitet-maler-skjermbakgrunn-mdx": () => import("./../../../content/identitet/maler/skjermbakgrunn.mdx" /* webpackChunkName: "component---content-identitet-maler-skjermbakgrunn-mdx" */),
  "component---content-identitet-maler-visittkort-mdx": () => import("./../../../content/identitet/maler/visittkort.mdx" /* webpackChunkName: "component---content-identitet-maler-visittkort-mdx" */),
  "component---content-identitet-maler-word-maler-mdx": () => import("./../../../content/identitet/maler/word-maler.mdx" /* webpackChunkName: "component---content-identitet-maler-word-maler-mdx" */),
  "component---content-identitet-verktoykassen-animasjoner-mdx": () => import("./../../../content/identitet/verktoykassen/animasjoner.mdx" /* webpackChunkName: "component---content-identitet-verktoykassen-animasjoner-mdx" */),
  "component---content-identitet-verktoykassen-co-branding-mdx": () => import("./../../../content/identitet/verktoykassen/co-branding.mdx" /* webpackChunkName: "component---content-identitet-verktoykassen-co-branding-mdx" */),
  "component---content-identitet-verktoykassen-datavisualisering-mdx": () => import("./../../../content/identitet/verktoykassen/datavisualisering.mdx" /* webpackChunkName: "component---content-identitet-verktoykassen-datavisualisering-mdx" */),
  "component---content-identitet-verktoykassen-en-tur-mdx": () => import("./../../../content/identitet/verktoykassen/en---tur.mdx" /* webpackChunkName: "component---content-identitet-verktoykassen-en-tur-mdx" */),
  "component---content-identitet-verktoykassen-farger-mdx": () => import("./../../../content/identitet/verktoykassen/farger.mdx" /* webpackChunkName: "component---content-identitet-verktoykassen-farger-mdx" */),
  "component---content-identitet-verktoykassen-foto-mdx": () => import("./../../../content/identitet/verktoykassen/foto.mdx" /* webpackChunkName: "component---content-identitet-verktoykassen-foto-mdx" */),
  "component---content-identitet-verktoykassen-grafiskeelementer-mdx": () => import("./../../../content/identitet/verktoykassen/grafiskeelementer.mdx" /* webpackChunkName: "component---content-identitet-verktoykassen-grafiskeelementer-mdx" */),
  "component---content-identitet-verktoykassen-ikoner-mdx": () => import("./../../../content/identitet/verktoykassen/ikoner.mdx" /* webpackChunkName: "component---content-identitet-verktoykassen-ikoner-mdx" */),
  "component---content-identitet-verktoykassen-illustrasjoner-mdx": () => import("./../../../content/identitet/verktoykassen/illustrasjoner.mdx" /* webpackChunkName: "component---content-identitet-verktoykassen-illustrasjoner-mdx" */),
  "component---content-identitet-verktoykassen-logo-mdx": () => import("./../../../content/identitet/verktoykassen/logo.mdx" /* webpackChunkName: "component---content-identitet-verktoykassen-logo-mdx" */),
  "component---content-identitet-verktoykassen-typografi-mdx": () => import("./../../../content/identitet/verktoykassen/typografi.mdx" /* webpackChunkName: "component---content-identitet-verktoykassen-typografi-mdx" */),
  "component---content-kom-i-gang-for-designere-brukergrupper-mdx": () => import("./../../../content/kom-i-gang/for-designere/brukergrupper.mdx" /* webpackChunkName: "component---content-kom-i-gang-for-designere-brukergrupper-mdx" */),
  "component---content-kom-i-gang-for-designere-brukerhistorier-mdx": () => import("./../../../content/kom-i-gang/for-designere/brukerhistorier.mdx" /* webpackChunkName: "component---content-kom-i-gang-for-designere-brukerhistorier-mdx" */),
  "component---content-kom-i-gang-for-designere-brukertesting-mdx": () => import("./../../../content/kom-i-gang/for-designere/brukertesting.mdx" /* webpackChunkName: "component---content-kom-i-gang-for-designere-brukertesting-mdx" */),
  "component---content-kom-i-gang-for-designere-for-designere-mdx": () => import("./../../../content/kom-i-gang/for-designere/for-designere.mdx" /* webpackChunkName: "component---content-kom-i-gang-for-designere-for-designere-mdx" */),
  "component---content-kom-i-gang-for-utviklere-bidra-med-kode-mdx": () => import("./../../../content/kom-i-gang/for-utviklere/bidraMedKode.mdx" /* webpackChunkName: "component---content-kom-i-gang-for-utviklere-bidra-med-kode-mdx" */),
  "component---content-kom-i-gang-for-utviklere-for-utviklere-mdx": () => import("./../../../content/kom-i-gang/for-utviklere/for-utviklere.mdx" /* webpackChunkName: "component---content-kom-i-gang-for-utviklere-for-utviklere-mdx" */),
  "component---content-kom-i-gang-index-mdx": () => import("./../../../content/kom-i-gang/index.mdx" /* webpackChunkName: "component---content-kom-i-gang-index-mdx" */),
  "component---content-kom-i-gang-introduksjon-arbeidskultur-mdx": () => import("./../../../content/kom-i-gang/introduksjon/arbeidskultur.mdx" /* webpackChunkName: "component---content-kom-i-gang-introduksjon-arbeidskultur-mdx" */),
  "component---content-kom-i-gang-introduksjon-designprinsipper-mdx": () => import("./../../../content/kom-i-gang/introduksjon/designprinsipper.mdx" /* webpackChunkName: "component---content-kom-i-gang-introduksjon-designprinsipper-mdx" */),
  "component---content-kom-i-gang-introduksjon-hva-vi-gjor-mdx": () => import("./../../../content/kom-i-gang/introduksjon/hva-vi-gjor.mdx" /* webpackChunkName: "component---content-kom-i-gang-introduksjon-hva-vi-gjor-mdx" */),
  "component---content-kom-i-gang-introduksjon-om-entur-mdx": () => import("./../../../content/kom-i-gang/introduksjon/om-entur.mdx" /* webpackChunkName: "component---content-kom-i-gang-introduksjon-om-entur-mdx" */),
  "component---content-tokens-fargetokens-darkmode-mdx": () => import("./../../../content/tokens/fargetokens/darkmode.mdx" /* webpackChunkName: "component---content-tokens-fargetokens-darkmode-mdx" */),
  "component---content-tokens-fargetokens-generelt-mdx": () => import("./../../../content/tokens/fargetokens/generelt.mdx" /* webpackChunkName: "component---content-tokens-fargetokens-generelt-mdx" */),
  "component---content-tokens-fargetokens-oversikt-farger-mdx": () => import("./../../../content/tokens/fargetokens/oversikt-farger.mdx" /* webpackChunkName: "component---content-tokens-fargetokens-oversikt-farger-mdx" */),
  "component---content-tokens-index-mdx": () => import("./../../../content/tokens/index.mdx" /* webpackChunkName: "component---content-tokens-index-mdx" */),
  "component---content-tokens-ovrigetokens-border-mdx": () => import("./../../../content/tokens/ovrigetokens/border.mdx" /* webpackChunkName: "component---content-tokens-ovrigetokens-border-mdx" */),
  "component---content-tokens-ovrigetokens-breakpoints-mdx": () => import("./../../../content/tokens/ovrigetokens/breakpoints.mdx" /* webpackChunkName: "component---content-tokens-ovrigetokens-breakpoints-mdx" */),
  "component---content-tokens-ovrigetokens-pxrem-mdx": () => import("./../../../content/tokens/ovrigetokens/pxrem.mdx" /* webpackChunkName: "component---content-tokens-ovrigetokens-pxrem-mdx" */),
  "component---content-tokens-ovrigetokens-shadows-mdx": () => import("./../../../content/tokens/ovrigetokens/shadows.mdx" /* webpackChunkName: "component---content-tokens-ovrigetokens-shadows-mdx" */),
  "component---content-tokens-ovrigetokens-spacing-mdx": () => import("./../../../content/tokens/ovrigetokens/spacing.mdx" /* webpackChunkName: "component---content-tokens-ovrigetokens-spacing-mdx" */),
  "component---content-tokens-ovrigetokens-timings-mdx": () => import("./../../../content/tokens/ovrigetokens/timings.mdx" /* webpackChunkName: "component---content-tokens-ovrigetokens-timings-mdx" */),
  "component---content-tokens-ovrigetokens-typografi-mdx": () => import("./../../../content/tokens/ovrigetokens/typografi.mdx" /* webpackChunkName: "component---content-tokens-ovrigetokens-typografi-mdx" */),
  "component---content-tokens-ovrigetokens-zindex-mdx": () => import("./../../../content/tokens/ovrigetokens/zindex.mdx" /* webpackChunkName: "component---content-tokens-ovrigetokens-zindex-mdx" */),
  "component---content-universell-utforming-farger-mdx": () => import("./../../../content/universell-utforming/farger.mdx" /* webpackChunkName: "component---content-universell-utforming-farger-mdx" */),
  "component---content-universell-utforming-hvem-designer-mdx": () => import("./../../../content/universell-utforming/hvem-designer.mdx" /* webpackChunkName: "component---content-universell-utforming-hvem-designer-mdx" */),
  "component---content-universell-utforming-index-mdx": () => import("./../../../content/universell-utforming/index.mdx" /* webpackChunkName: "component---content-universell-utforming-index-mdx" */),
  "component---content-universell-utforming-nyttige-verktoy-mdx": () => import("./../../../content/universell-utforming/nyttige-verktoy.mdx" /* webpackChunkName: "component---content-universell-utforming-nyttige-verktoy-mdx" */),
  "component---content-universell-utforming-testguide-mdx": () => import("./../../../content/universell-utforming/testguide.mdx" /* webpackChunkName: "component---content-universell-utforming-testguide-mdx" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

